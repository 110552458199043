html, body {
  height: 100%;
  overflow: hidden;
}

body {
  min-height: 100%;
  font-family: 'Karla', sans-serif;
}

@media (max-width: 900px) {
  img {
    max-width: 100%;
    height: auto;
  }
}

.calcom-atoms div[data-state="open"] {
  z-index: 1450;
}

.calcom-atoms div[role="dialog"] {
  z-index: 1500;
}

#root {
  height: 100%;
}

